<template>
  <center>
    <h1 class="text-light">(404) Page NotFound</h1>
    <p>
      <a href="/">Go dashboard?</a>
    </p>
  </center>
</template>

<script>

  export default {
    name: 'NotFound'
  }

</script>

<style scoped>

  center {
    margin: 15vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }

</style>